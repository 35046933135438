import React from 'react'

import PageWrapper from '../components/PageWrapper'
import OurRentals from '../sections/landing1/OurRentals'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Section, Title, Text } from '../components/Core'

const ServicesPage = ({data}) => {
  return (
    <>
      <PageWrapper darkTheme={false}>
      <SEO title="404" />

      <Section hero className='text-center container' pb="0px">

<Title>404 Not Found</Title>
<Text variant="small" color="black" className='pt-5'>Sadly the page you are looking for can no longer be found. If you are looking for rental properties, feel free to take a look at our other listings.</Text>
<OurRentals properties={data}/>

</Section>
      </PageWrapper>
    </>
  )
}
export default ServicesPage


export const query = graphql`
query notFound {
  allListings(sort: {fields: id__normalized, order: DESC}) {
    nodes {
      price
      bathrooms
      bedrooms
      carports
      streetNo
      street
      suburb
      postcode
      mainImageUrl
      garages
    }
  }
}
`